@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=La+Belle+Aurore&display=swap');

@layer utilities {
    
        .text-shadow {
          text-shadow: rgba(23, 32, 38, 0.2) 0px 2px 10px, rgba(23, 32, 38, 0.2) 0px 2px 4px
        }
        
        .text-shadow-none {
          text-shadow: none;
        }
    
}
@layer base {
    body {
        @apply bg-gradient-to-r from-slate-600 to-slate-900;
    }
}
@layer components {
    .floating_header { 
        @apply text-6xl font-josefin drop-shadow-[0_1.8px_1.8px_rgba(0,0,0,0.9)] text-shadow text-white tracking-wide text-center sm:text-6xl ;
    }

    .floating_sub_header {
        @apply text-5xl font-belle drop-shadow-[0_1.8px_1.8px_rgba(0,0,0,0.9)] text-shadow text-laziz-green tracking-wide text-center  ;
    }

    .title_text {
        @apply text-3xl xl:text-3xl 2xl:text-5xl mt-6 lg:mt-0 2xl:mt-6 2xl:mb-6 text-white font-bold text-center; 
    }

    .paragraph_text {
        @apply text-shadow mt-8 max-w-lg pl-4 pr-4 text-left text-lg text-laziz-white drop-shadow-[0_1.8px_1.8px_rgba(0,0,0,0.9)] lg:pl-2 lg:pr-2 xl:max-w-3xl 2xl:mt-2 2xl:text-lg
    }
  }

